<script setup lang="ts">
import { daysOfWeek } from '../utils/time.ts'

const modelValue = defineModel<number>({
  required: true
})

const options = daysOfWeek.map((v, i) => {
  return {
    label: v,
    value: i
  }
})
</script>

<template>
  <a-select
    v-model:value="modelValue"
    :options="options"
    placeholder="请选择日期"
  />
</template>
