export const daysOfWeek = [
  '星期日',
  '星期一',
  '星期二',
  '星期三',
  '星期四',
  '星期五',
  '星期六'
]

export const formatDayOfWeek = (value: number): string => {
  return daysOfWeek[value]
}

export const formatTimeOfDay = (value: number, seconds?: boolean): string => {
  return (seconds ? [
    Math.floor(value / 3600),
    Math.floor((value % 3600) / 60),
    value % 60
  ] : [
    Math.floor(value / 60),
    value % 60
  ]).map((v) => {
    return v.toString().padStart(2, '0')
  }).join(':')
}
