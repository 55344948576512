<script setup lang="ts">
import { useMyFetch } from '../fetch.ts'
import { useStore } from '../store.ts'
import type { HospitalStats } from '../types/hospital.ts'

const store = useStore()

const {
  data,
  isFetching
} = useMyFetch(() => `/api/hospitals/${store.hospital?.id}/stats`, {
  refetch: true
}).json<HospitalStats>()
</script>

<template>
  <template v-if="store.hospital">
    <div
      v-if="isFetching"
      class="flex items-center justify-center py-8"
    >
      <a-spin size="large" />
    </div>
    <div
      v-else-if="data"
      class="flex flex-col gap-6"
    >
      <div class="grid grid-cols-4 gap-6">
        <div class="px-6 py-4 bg-white rounded-lg shadow-sm">
          <a-statistic
            title="总院区"
            :value="data.campusCount"
          />
        </div>
        <div class="px-6 py-4 bg-white rounded-lg shadow-sm">
          <a-statistic
            title="总科室"
            :value="data.departmentCount"
          />
        </div>
        <div class="px-6 py-4 bg-white rounded-lg shadow-sm">
          <a-statistic
            title="总医生"
            :value="data.doctorCount"
          />
        </div>
        <div class="px-6 py-4 bg-white rounded-lg shadow-sm">
          <a-statistic
            title="总护士"
            :value="data.nurseCount"
          />
        </div>
      </div>
      <div class="grid grid-cols-3 gap-6">
        <div class="px-6 py-4 bg-white rounded-lg shadow-sm">
          <a-statistic
            title="总床位"
            :value="data.bedCount"
          />
        </div>
        <div class="px-6 py-4 bg-white rounded-lg shadow-sm">
          <a-statistic
            title="使用中床位"
            :value="data.bedUsedCount"
          />
        </div>
        <div class="px-6 py-4 bg-white rounded-lg shadow-sm">
          <a-statistic
            title="床位使用率"
            :value="Number(data.bedUtilization) * 100"
            :precision="1"
            suffix="%"
          />
        </div>
      </div>
      <div class="grid grid-cols-3 gap-6">
        <div class="px-6 py-4 bg-white rounded-lg shadow-sm">
          <a-statistic
            title="总患者"
            :value="data.patientCount"
          />
        </div>
        <div class="px-6 py-4 bg-white rounded-lg shadow-sm">
          <a-statistic
            title="总门诊人次"
            :value="data.outpatientTotalCount"
          />
        </div>
        <div class="px-6 py-4 bg-white rounded-lg shadow-sm">
          <a-statistic
            title="总住院人次"
            :value="data.hospitalizationTotalCount"
          />
        </div>
      </div>
    </div>
  </template>
  <a-alert
    v-else
    type="warning"
    message="暂无医院信息"
    show-icon
  />
</template>
