<script setup lang="ts">
import { computed, watchEffect } from 'vue'
import type { SelectProps } from 'ant-design-vue'

import { useMyFetch } from '../fetch.ts'
import { useStore } from '../store.ts'
import type { HospitalDepartment } from '../types/hospital.ts'

const props = withDefaults(defineProps<{
  allowEmpty?: boolean
}>(), {
  allowEmpty: false
})

const modelValue = defineModel<number>({
  default: 0
})

const store = useStore()

const {
  data
} = useMyFetch(() => {
  return `/api/hospitals/${store.hospital?.id ?? ''}/campuses`
}, {
  refetch: true
}).json<HospitalDepartment[]>()

const options = computed<NonNullable<SelectProps['options']>>(() => {
  const result = data.value?.map((v) => ({
    label: v.name,
    value: v.id
  })) ?? []
  if (props.allowEmpty) {
    result.unshift({
      value: 0,
      label: '无院区属性'
    })
  }
  return result.length ? result : [{
    value: 0,
    label: '暂无院区信息'
  }]
})

watchEffect(() => {
  if (!options.value.some((o) => o.value === modelValue.value)) {
    modelValue.value = options.value[0].value as number
  }
})
</script>

<template>
  <a-select
    v-model:value="modelValue"
    :options="options"
    placeholder="请选择科室"
  />
</template>
