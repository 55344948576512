<script setup lang="ts">
import { ref } from 'vue'
import type { FormInstance } from 'ant-design-vue'
import { message, Modal } from 'ant-design-vue'

import { myFetchToPromise, useMyFetch } from '../fetch.ts'
import { useStore } from '../store.ts'
import type { ResetPasswordRequest, User } from '../types/user.ts'

const store = useStore()

const {
  data,
  execute,
  isFetching
} = useMyFetch('/api/users').json<User[]>()

const isDeleteDisabled = (record: User) => {
  return record.id === store.user?.user.id
    || record.roles.some(role => role.id === 'hospital_admin')
}

const modalResetPasswordOpen = ref(false)

const formResetPasswordRef = ref<FormInstance | null>(null)

const formResetPassword = ref<ResetPasswordRequest>({
  username: '',
  newPassword: ''
})

const handleResetPassword = (username: string) => {
  modalResetPasswordOpen.value = true
  formResetPassword.value = {
    username,
    newPassword: ''
  }
}

const handleCancelResetPassword = () => {
  formResetPasswordRef.value?.clearValidate()
}

const handleResetPasswordSubmit = async () => {
  if (!formResetPassword.value.username) {
    return
  }

  try {
    await formResetPasswordRef.value?.validate()
    await myFetchToPromise(useMyFetch(`/api/user/reset-password`)
      .post(formResetPassword))
    message.success('密码重置成功')
    modalResetPasswordOpen.value = false
  } catch (ignored) {}
}

const handleDelete = (id: number) => {
  Modal.confirm({
    title: '注意',
    content: '确定要删除此用户吗？所有关联的资源都将被一并删除',
    onOk: async () => {
      await myFetchToPromise(useMyFetch(`/api/users/${id}`)
        .delete())
      await execute(true)
      message.success('删除成功')
    }
  })
}
</script>

<template>
  <a-table
    :columns="[
      {
        title: 'ID',
        dataIndex: 'id',
      },
      {
        title: '用户名',
        dataIndex: 'username',
      },
      {
        title: '角色',
        dataIndex: 'roles'
      },
      {
        key: 'actions',
        align: 'right'
      }
    ]"
    row-key="id"
    :data-source="data"
    :loading="isFetching"
  >
    <template #bodyCell="{ column, value, record }">
      <template v-if="column.dataIndex === 'roles'">
        <a-tag
          v-for="role of value"
          :key="role.id"
          color="blue"
          v-text="role.name"
        />
      </template>
      <template v-if="column.key === 'actions'">
        <a-space>
          <a-button
            type="link"
            size="small"
            @click="handleResetPassword(record.username)"
          >
            重置密码
          </a-button>
          <a-button
            type="link"
            danger
            size="small"
            :disabled="isDeleteDisabled(record)"
            @click="handleDelete(record.id)"
          >
            删除
          </a-button>
        </a-space>
      </template>
    </template>
  </a-table>
  <a-modal
    v-model:open="modalResetPasswordOpen"
    title="重置密码"
    ok-text="提交"
    @cancel="handleCancelResetPassword"
    @ok="handleResetPasswordSubmit"
  >
    <a-form
      ref="formResetPasswordRef"
      :model="formResetPassword"
      layout="vertical"
    >
      <a-form-item
        name="username"
        label="用户名"
        :rules="[{ required: true, message: '请输入用户名' }]"
      >
        <a-input
          :value="formResetPassword.username"
          disabled
        />
      </a-form-item>
      <a-form-item
        name="newPassword"
        label="新密码"
        :rules="[{ required: true, message: '请输入新密码' }]"
      >
        <a-input-password
          v-model:value="formResetPassword.newPassword"
          autocomplete="new-password"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
