<script setup lang="ts">
import { ref } from 'vue'
import type { FormInstance } from 'ant-design-vue'
import { message, Modal } from 'ant-design-vue'
import { PlusOutlined } from '@ant-design/icons-vue'

import { myFetchToPromise, useMyFetch } from '../fetch.ts'
import type { Patient, UpdatePatientRequest } from '../types/patient.ts'

const {
  execute,
  data,
  isFetching
} = useMyFetch('/api/user/patients').json<Patient[]>()

const modalEditOpen = ref(false)

const editingId = ref<number | null>(null)

const formEditRef = ref<FormInstance | null>(null)

const formEdit = ref<UpdatePatientRequest>({
  name: '',
  gender: '',
  phone: '',
  idCard: ''
})

const handleCreate = () => {
  modalEditOpen.value = true
  editingId.value = null
  formEdit.value = {
    name: '',
    gender: '',
    phone: '',
    idCard: ''
  }
}

const handleEdit = (id: number) => {
  editingId.value = id
  useMyFetch(`/api/user/patients/${id}`).json<Patient>().then(({ data }) => {
    if (data.value) {
      formEdit.value = {
        name: data.value.name,
        gender: data.value.gender,
        phone: data.value.phone,
        idCard: data.value.idCard
      }
      modalEditOpen.value = true
    }
  })
}

const handleSubmitEdit = async () => {
  await formEditRef.value?.validate()
  try {
    if (editingId.value === null) {
      await myFetchToPromise(useMyFetch('/api/user/patients')
        .post(formEdit.value))
    } else {
      await myFetchToPromise(useMyFetch(`/api/user/patients/${editingId.value}`)
        .put(formEdit.value))
    }
    await execute(true)
    message.success('保存成功')
    modalEditOpen.value = false
  } catch (error) {
    console.error(error)
    message.error('保存失败')
  }
}

const handleCancelEdit = () => {
  formEditRef.value?.clearValidate()
}

const handleDelete = (id: number) => {
  Modal.confirm({
    title: '注意',
    content: '确定要删除此就诊人信息吗？您将无法再查看与此就诊人关联的就诊记录',
    onOk: async () => {
      await myFetchToPromise(useMyFetch(`/api/user/patients/${id}`)
        .delete())
      await execute(true)
      message.success('删除成功')
    }
  })
}
</script>

<template>
  <div class="mb-4">
    <a-button
      type="primary"
      @click="handleCreate"
    >
      <plus-outlined />
      添加就诊人
    </a-button>
  </div>
  <a-table
    :columns="[
      {
        title: '姓名',
        dataIndex: 'name',
      },
      {
        title: '性别',
        dataIndex: 'gender'
      },
      {
        title: '年龄',
        dataIndex: 'age'
      },
      {
        title: '手机号',
        dataIndex: 'phone'
      },
      {
        title: '证件号',
        dataIndex: 'idCard'
      },
      {
        key: 'actions',
        align: 'right'
      }
    ]"
    row-key="id"
    :data-source="data"
    :loading="isFetching"
  >
    <template #bodyCell="{ column, record }">
      <template v-if="column.key === 'actions'">
        <a-space>
          <a-button
            type="link"
            size="small"
            @click="handleEdit(record.id)"
          >
            编辑
          </a-button>
          <a-button
            type="link"
            danger
            size="small"
            @click="handleDelete(record.id)"
          >
            删除
          </a-button>
        </a-space>
      </template>
    </template>
  </a-table>
  <a-modal
    v-model:open="modalEditOpen"
    :title="editingId ? '编辑就诊人' : '添加就诊人'"
    ok-text="提交"
    @cancel="handleCancelEdit"
    @ok="handleSubmitEdit"
  >
    <a-form
      ref="formEditRef"
      :model="formEdit"
      layout="vertical"
    >
      <a-form-item
        label="就诊人姓名"
        name="name"
        :rules="[{ required: true, message: '请输入就诊人姓名' }]"
      >
        <a-input
          v-model:value="formEdit.name"
          :maxlength="80"
        />
      </a-form-item>
      <a-form-item
        label="就诊人性别"
        name="gender"
        :rules="[{ required: true, message: '请输入就诊人性别' }]"
      >
        <a-input
          v-model:value="formEdit.gender"
          :maxlength="80"
        />
      </a-form-item>
      <a-form-item
        label="就诊人手机号"
        name="phone"
        :rules="[{ required: true, message: '请输入就诊人手机号' }]"
      >
        <a-input
          v-model:value="formEdit.phone"
          :maxlength="80"
        />
      </a-form-item>
      <a-form-item
        label="就诊人证件号"
        name="idCard"
        :rules="[{ required: true, message: '请输入就诊人证件号' }]"
      >
        <a-input
          v-model:value="formEdit.idCard"
          :maxlength="80"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
