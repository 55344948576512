<script setup lang="ts">
const modelValue = defineModel<number>({
  required: true
})
</script>

<template>
  <a-input-number
    :value="modelValue / 100"
    addon-before="¥"
    :precision="2"
    :min="0"
    class="!w-full"
    @update:value="modelValue = Math.round($event * 100)"
  />
</template>
