import { createApp } from 'vue'
import { createPinia } from 'pinia'
import {
  Alert,
  AutoComplete,
  Avatar,
  Button,
  ConfigProvider,
  Divider,
  Form,
  Input,
  InputNumber,
  Layout,
  Menu,
  Modal,
  PageHeader,
  Select,
  Space,
  Spin,
  Statistic,
  StyleProvider,
  Table,
  Tabs,
  Tag,
  TimePicker,
  Typography
} from 'ant-design-vue'

import router from './router'
import App from './App.vue'

import 'ant-design-vue/dist/reset.css'
import './assets/css/tailwind.css'

const app = createApp(App)

// register components
app.use(Alert)
app.use(AutoComplete)
app.use(Avatar)
app.use(Button)
app.use(ConfigProvider)
app.use(Divider)
app.use(Form)
app.use(Input)
app.use(InputNumber)
app.use(Layout)
app.use(Menu)
app.use(Modal)
app.use(PageHeader)
app.use(Select)
app.use(Space)
app.use(Spin)
app.use(Statistic)
app.use(StyleProvider)
app.use(Table)
app.use(Tabs)
app.use(Tag)
app.use(TimePicker)
app.use(Typography)

// register libraries
app.use(createPinia())
app.use(router)

app.mount('#app')
