<script setup lang="ts">
import { computed, ref } from 'vue'
import { watchDebounced } from '@vueuse/core'
import type { AutoCompleteProps } from 'ant-design-vue'

import { useMyFetch } from '../fetch.ts'
import { useStore } from '../store.ts'

const modelValue = defineModel<string>({
  required: true
})

const store = useStore()

const canAutocomplete = computed<boolean>(() => {
  return store.hasRole(['admin', 'hospital_admin'])
})

const options = ref<NonNullable<AutoCompleteProps['options']>>([])

watchDebounced(modelValue, (v) => {
  if (v && /^[ -~]+$/.test(v) && canAutocomplete) {
    const url = '/api/autocomplete/users?' + (new URLSearchParams({
      search: v
    }).toString())
    useMyFetch(url).json<string[]>().then(({ data }) => {
      if (data.value) {
        options.value = data.value.map((username) => ({
          value: username,
          text: username
        }))
      }
    })
  } else {
    options.value = []
  }
}, {
  immediate: true,
  debounce: 200
})
</script>

<template>
  <a-auto-complete
    v-model:value="modelValue"
    :maxlength="32"
    allow-clear
    backfill
    :options="options"
    v-bind="$attrs"
  />
</template>
