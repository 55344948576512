<script setup lang="ts">
import { computed, ref } from 'vue'
import type { FormInstance } from 'ant-design-vue'
import { message, Modal } from 'ant-design-vue'
import { PlusOutlined } from '@ant-design/icons-vue'

import { myFetchToPromise, useMyFetch } from '../fetch.ts'
import { useStore } from '../store.ts'
import { formatMoney } from '../utils/money.ts'
import { daysOfWeek, formatTimeOfDay } from '../utils/time.ts'
import SelectCampus from '../components/SelectCampus.vue'
import SelectDayOfWeek from '../components/SelectDayOfWeek.vue'
import SelectDepartment from '../components/SelectDepartment.vue'
import SelectTimeOfDay from '../components/SelectTimeOfDay.vue'
import InputMoney from '../components/InputMoney.vue'
import type { OutpatientTime, UpdateOutpatientTimeRequest } from '../types/outpatient.ts'

const store = useStore()

const hospitalId = computed<string>(() => {
  return store.hospital?.id.toString() ?? ''
})

const {
  execute,
  data,
  isFetching
} = useMyFetch(() => {
  return `/api/hospitals/${hospitalId.value}/outpatient/times`
}, {
  refetch: true
}).json<OutpatientTime[]>()

const modalEditOpen = ref(false)

const editingId = ref<number | null>(null)

const formEditRef = ref<FormInstance | null>(null)

const formEdit = ref<Required<UpdateOutpatientTimeRequest>>({
  campus: 0,
  department: 0,
  doctorType: '',
  name: '',
  date: 0,
  startTime: 0,
  endTime: 0,
  capacity: 0,
  price: 0
})

const formEditValidateEndTime = async (_: unknown, value: number) => {
  if (value <= formEdit.value.startTime) {
    return Promise.reject()
  }
}

const handleCreate = () => {
  modalEditOpen.value = true
  editingId.value = null
  formEdit.value = {
    campus: 0,
    department: 0,
    doctorType: '',
    name: '',
    date: 1,
    startTime: 540,
    endTime: 1020,
    capacity: 100,
    price: 5000
  }
}

const handleEdit = (id: number) => {
  editingId.value = id
  useMyFetch(`/api/outpatient/times/${id}`).json<OutpatientTime>().then(({ data }) => {
    if (data.value) {
      formEdit.value = {
        campus: data.value.campus?.id || 0,
        department: data.value.department.id,
        doctorType: data.value.doctorType,
        name: data.value.name,
        date: data.value.date,
        startTime: data.value.startTime,
        endTime: data.value.endTime,
        capacity: data.value.capacity,
        price: data.value.price
      }
      modalEditOpen.value = true
    }
  })
}

const handleSubmitEdit = async () => {
  await formEditRef.value?.validate()
  try {
    if (editingId.value === null) {
      await myFetchToPromise(useMyFetch(`/api/hospitals/${hospitalId.value}/outpatient/times`)
        .post(formEdit.value))
    } else {
      await myFetchToPromise(useMyFetch(`/api/outpatient/times/${editingId.value}`)
        .put(formEdit.value))
    }
    await execute(true)
    message.success('保存成功')
    modalEditOpen.value = false
  } catch (error) {
    console.error(error)
    message.error('保存失败')
  }
}

const handleCancelEdit = () => {
  formEditRef.value?.clearValidate()
}

const handleDelete = (id: number) => {
  Modal.confirm({
    title: '注意',
    content: '确定要删除此出诊时段吗？所有关联的挂号和就诊信息都将被一并删除',
    onOk: async () => {
      await myFetchToPromise(useMyFetch(`/api/outpatient/times/${id}`)
        .delete())
      await execute(true)
      message.success('删除成功')
    }
  })
}

const handleEnable = (id: number) => {
  Modal.confirm({
    title: '注意',
    content: '确定要启用此出诊时段吗？',
    onOk: async () => {
      await myFetchToPromise(useMyFetch(`/api/outpatient/times/${id}/enable`)
        .post())
      await execute(true)
      message.success('启用成功')
    }
  })
}

const handleDisable = (id: number) => {
  Modal.confirm({
    title: '注意',
    content: '确定要停用此出诊时段吗？',
    onOk: async () => {
      await myFetchToPromise(useMyFetch(`/api/outpatient/times/${id}/disable`)
        .post())
      await execute(true)
      message.success('停用成功')
    }
  })
}
</script>

<template>
  <div class="flex items-center mb-4">
    <a-button
      type="primary"
      @click="handleCreate"
    >
      <plus-outlined />
      新建出诊时段
    </a-button>
  </div>
  <a-table
    :columns="[
      {
        key: 'name'
      },
      {
        title: '时间',
        key: 'time'
      },
      {
        title: '容量',
        dataIndex: 'capacity'
      },
      {
        title: '价格',
        key: 'price'
      },
      {
        key: 'actions',
        align: 'right'
      }
    ]"
    row-key="id"
    :data-source="data"
    :loading="isFetching"
  >
    <template #bodyCell="{ column, record }">
      <template v-if="column.key === 'name'">
        {{ record.doctorType }} - {{ record.name }}
        <template v-if="!record.enabled">
          <a-tag color="red">已停用</a-tag>
        </template>
      </template>
      <template v-else-if="column.key === 'time'">
        {{ daysOfWeek[record.date] }}
        {{ formatTimeOfDay(record.startTime) }} - {{ formatTimeOfDay(record.endTime) }}
      </template>
      <template v-else-if="column.key === 'price'">
        {{ formatMoney(record.price) }}
      </template>
      <template v-else-if="column.key === 'actions'">
        <a-space>
          <a-button
            type="link"
            size="small"
            @click="handleEdit(record.id)"
          >
            编辑
          </a-button>
          <a-button
            v-if="!record.enabled"
            type="link"
            size="small"
            @click="handleEnable(record.id)"
          >
            启用
          </a-button>
          <a-button
            v-else
            type="link"
            danger
            size="small"
            @click="handleDisable(record.id)"
          >
            停用
          </a-button>
          <a-button
            type="link"
            danger
            size="small"
            @click="handleDelete(record.id)"
          >
            删除
          </a-button>
        </a-space>
      </template>
    </template>
  </a-table>
  <a-modal
    v-model:open="modalEditOpen"
    :title="editingId ? '编辑出诊时段' : '新建出诊时段'"
    ok-text="提交"
    @cancel="handleCancelEdit"
    @ok="handleSubmitEdit"
  >
    <a-form
      ref="formEditRef"
      :model="formEdit"
      layout="vertical"
    >
      <a-form-item
        label="院区"
        name="campus"
        :rules="[{ type: 'number', min: 0, message: '请选择出诊院区' }]"
      >
        <select-campus
          v-model="formEdit.campus"
          allow-empty
        />
      </a-form-item>
      <a-form-item
        label="科室"
        name="department"
        :rules="[{ type: 'number', required: true, min: 1, message: '请选择出诊科室' }]"
      >
        <select-department v-model="formEdit.department" />
      </a-form-item>
      <a-form-item
        label="医生类型"
        name="doctorType"
        extra="如专家号、普通号等"
        :rules="[{ required: true, message: '请输入医生类型' }]"
      >
        <a-input
          v-model:value="formEdit.doctorType"
          :maxlength="80"
        />
      </a-form-item>
      <a-form-item
        label="时段名称"
        name="name"
        extra="如上午、下午等"
        :rules="[{ required: true, message: '请输入时段名称' }]"
      >
        <a-input
          v-model:value="formEdit.name"
          :maxlength="80"
        />
      </a-form-item>
      <a-form-item
        label="日期"
        name="date"
        :rules="[{ type: 'number', required: true, min: 0, max: 6, message: '请选择日期' }]"
      >
        <select-day-of-week v-model="formEdit.date" />
      </a-form-item>
      <div class="flex items-center gap-4">
        <a-form-item
          class="flex-1"
          label="开始时间"
          name="startTime"
          :rules="[{ type: 'number', required: true, min: 0, max: 1339, message: '请选择开始时间' }]"
        >
          <select-time-of-day v-model="formEdit.startTime" />
        </a-form-item>
        <a-form-item
          class="flex-1"
          label="结束时间"
          name="endTime"
          :rules="[
            { type: 'number', required: true, min: 0, max: 1339, message: '请选择结束时间' },
            { validator: formEditValidateEndTime, message: '结束时间必须晚于开始时间' }
          ]"
        >
          <select-time-of-day v-model="formEdit.endTime" />
        </a-form-item>
      </div>
      <a-form-item
        label="容量"
        name="capacity"
        :rules="[{ type: 'number', required: true, min: 1, message: '请输入有效的容量' }]"
      >
        <a-input-number
          v-model:value="formEdit.capacity"
          :min="0"
          :precision="0"
          class="!w-full"
        />
      </a-form-item>
      <a-form-item
        label="挂号价格"
        name="price"
        :rules="[{ type: 'number', required: true, min: 0, message: '请输入有效的价格' }]"
      >
        <input-money v-model="formEdit.price" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
