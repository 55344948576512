<script setup lang="ts">
import { computed } from 'vue'

import { formatTimeOfDay } from '../utils/time.ts'

const props = withDefaults(defineProps<{
  seconds?: boolean
  placeholder?: string
}>(), {
  seconds: false,
  placeholder: '请选择时间'
})

const modelValue = defineModel<number>({
  required: true
})

const value = computed<string>(() => {
  return formatTimeOfDay(modelValue.value, props.seconds)
})

const handleUpdate = (value: string) => {
  if (!value) {
    modelValue.value = 0
    return
  }

  let result = 0
  value.split(':').forEach((part, index, arr) => {
    result += parseInt(part) * Math.pow(60, arr.length - 1 - index)
  })
  modelValue.value = result
}
</script>

<template>
  <a-time-picker
    :value="value"
    :format="seconds ? 'HH:mm:ss' : 'HH:mm'"
    input-read-only
    :show-now="false"
    :placeholder="placeholder"
    :value-format="seconds ? 'HH:mm:ss' : 'HH:mm'"
    class="!w-full"
    @update:value="handleUpdate"
  />
</template>
