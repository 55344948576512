<script setup lang="ts">
import { computed, ref } from 'vue'
import type { FormInstance } from 'ant-design-vue'
import { message, Modal } from 'ant-design-vue'
import { PlusOutlined } from '@ant-design/icons-vue'

import { myFetchToPromise, useMyFetch } from '../fetch.ts'
import { useStore } from '../store.ts'
import type { HospitalDepartment, UpdateHospitalDepartmentRequest } from '../types/hospital.ts'

const store = useStore()

const hospitalId = computed<string>(() => {
  return store.hospital?.id.toString() ?? ''
})

const {
  execute,
  data,
  isFetching
} = useMyFetch(() => {
  return `/api/hospitals/${hospitalId.value}/departments`
}, {
  refetch: true
}).json<HospitalDepartment[]>()

const modalEditOpen = ref(false)

const editingId = ref<number | null>(null)

const formEditRef = ref<FormInstance | null>(null)

const formEdit = ref<Required<UpdateHospitalDepartmentRequest>>({
  name: '',
  phone: ''
})

const handleCreate = () => {
  modalEditOpen.value = true
  editingId.value = null
  formEdit.value = {
    name: '',
    phone: ''
  }
}

const handleEdit = (id: number) => {
  editingId.value = id
  useMyFetch(`/api/departments/${id}`).json<HospitalDepartment>().then(({ data }) => {
    if (data.value) {
      formEdit.value = {
        name: data.value.name,
        phone: data.value.phone || ''
      }
      modalEditOpen.value = true
    }
  })
}

const handleSubmitEdit = async () => {
  await formEditRef.value?.validate()
  try {
    if (editingId.value === null) {
      await myFetchToPromise(useMyFetch(`/api/hospitals/${hospitalId.value}/departments`)
        .post(formEdit.value))
    } else {
      await myFetchToPromise(useMyFetch(`/api/departments/${editingId.value}`)
        .put(formEdit.value))
    }
    await execute(true)
    message.success('保存成功')
    modalEditOpen.value = false
  } catch (error) {
    console.error(error)
    message.error('保存失败')
  }
}

const handleCancelEdit = () => {
  formEditRef.value?.clearValidate()
}

const handleDelete = (id: number) => {
  Modal.confirm({
    title: '注意',
    content: '确定要删除此科室吗？所有关联的资源都将被一并删除',
    onOk: async () => {
      await myFetchToPromise(useMyFetch(`/api/departments/${id}`)
        .delete())
      await execute(true)
      message.success('删除成功')
    }
  })
}
</script>

<template>
  <div class="flex items-center mb-4">
    <a-button
      type="primary"
      @click="handleCreate"
    >
      <plus-outlined />
      新建科室
    </a-button>
  </div>
  <a-table
    :columns="[
      {
        title: '名称',
        dataIndex: 'name',
      },
      {
        title: '联系电话',
        dataIndex: 'phone'
      },
      {
        key: 'actions',
        align: 'right'
      }
    ]"
    row-key="id"
    :data-source="data"
    :loading="isFetching"
  >
    <template #bodyCell="{ column, record }">
      <template v-if="column.key === 'actions'">
        <a-space>
          <a-button
            type="link"
            size="small"
            @click="handleEdit(record.id)"
          >
            编辑
          </a-button>
          <a-button
            type="link"
            danger
            size="small"
            @click="handleDelete(record.id)"
          >
            删除
          </a-button>
        </a-space>
      </template>
    </template>
  </a-table>
  <a-modal
    v-model:open="modalEditOpen"
    :title="editingId ? '编辑科室' : '新建科室'"
    ok-text="提交"
    @cancel="handleCancelEdit"
    @ok="handleSubmitEdit"
  >
    <a-form
      ref="formEditRef"
      :model="formEdit"
      layout="vertical"
    >
      <a-form-item
        label="科室名称"
        name="name"
        :rules="[{ required: true, message: '请输入科室名称' }]"
      >
        <a-input
          v-model:value="formEdit.name"
          :maxlength="80"
        />
      </a-form-item>
      <a-form-item
        label="联系电话"
        name="phone"
      >
        <a-input
          v-model:value="formEdit.phone"
          :maxlength="80"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
