<script setup lang="ts">
import { computed } from 'vue'

import type { HospitalBedWithUsage } from '../types/hospitalization.ts'

const props = defineProps<{
  value: HospitalBedWithUsage
  admin: boolean
}>()

defineEmits<{
  edit: [],
  delete: []
}>()

const showVitals = computed<boolean>(() => {
  return !!props.value.record
    && !!props.value.bed.type
    && props.value.bed.type.includes('ICU')
})
</script>

<template>
  <div class="bg-white rounded-lg shadow">
    <div class="flex items-center gap-2 px-4 py-3">
      <div class="flex-1 flex flex-col">
        <span
          v-if="value.bed.type"
          class="text-xs text-gray-500"
          v-text="value.bed.type"
        />
        <span
          class="text-base font-medium"
          v-text="value.bed.name"
        />
      </div>
      <span
        class="flex-shrink-0 text-xs"
        :class="value.record ? 'text-red-500' : 'text-green-500'"
      >
        {{ value.record ? '占用中' : '空闲中' }}
      </span>
    </div>
    <template v-if="showVitals">
      <div class="w-full h-[1px] bg-gray-200" />
      <div class="flex flex-col items-stretch px-4 py-2">
        <div class="flex justify-between items-center">
          <span class="text-xs text-gray-400">心电图</span>
          <span class="text-xs text-red-700">75 bpm</span>
        </div>
        <div class="flex justify-between items-center">
          <span class="text-xs text-gray-400">呼吸频率</span>
          <span class="text-xs text-amber-600">15 rpm</span>
        </div>
        <div class="flex justify-between items-center">
          <span class="text-xs text-gray-400">血氧饱和度</span>
          <span class="text-xs text-gray-600">95%</span>
        </div>
        <div class="flex justify-between items-center">
          <span class="text-xs text-gray-400">体温</span>
          <span class="text-xs text-gray-600">37.0°C</span>
        </div>
        <div class="flex justify-between items-center">
          <span class="text-xs text-gray-400">血压</span>
          <span class="text-xs text-gray-600">90/150 (90)</span>
        </div>
      </div>
    </template>
    <div class="w-full h-[1px] bg-gray-200" />
    <div class="flex justify-evenly items-center py-2">
      <a-button
        type="link"
        size="small"
      >
        详情
      </a-button>
      <a-button
        v-if="admin"
        type="link"
        size="small"
        @click="$emit('edit')"
      >
        编辑
      </a-button>
      <a-button
        v-if="admin"
        type="link"
        size="small"
        danger
        @click="$emit('delete')"
      >
        删除
      </a-button>
    </div>
  </div>
</template>
